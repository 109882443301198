
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { languages } from '@/definitions/_general/_data/languages'

export default defineComponent({
  name: 'ServicesTextToSpeech',
  components: {
    TmStatus,
    InfoCard,
    TmButton,
    TmAlert,
    PageContent,
  },
  setup() {
    const showAlert = ref(true)
    const target = ref('landlineNumbers')
    const gender = ref('female')
    const language = ref(languages[0])

    return {
      showAlert,
      target,
      gender,
      language,
      languages,
    }
  },
})
